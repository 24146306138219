import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

function isWithinOneHour(base64Timestamp) {
  // Base64デコード
  const decodedTimestamp = atob(base64Timestamp);

  // Unixタイムスタンプに変換
  const timestamp = parseInt(decodedTimestamp, 10);

  // 現在のUnixタイムスタンプをUTCで取得
  const currentTimestamp = Math.floor(new Date().getTime() / 1000);

  // 1時間以内かどうかを判定
  const oneHourInSeconds = 60 * 60;
  return Math.abs(currentTimestamp - timestamp) <= oneHourInSeconds;
}

const NewRedirectPage = (props) => {
  const { timestampToken, urlToken } = useParams();
  const navigate = useNavigate();

  console.log(timestampToken);
  console.log(urlToken);

  useEffect(() => {
    if (isWithinOneHour(timestampToken)) {
      window.location.href = atob(urlToken);
    } else {
      navigate('/');
    }
  }, []);

  return <div>Redirect...</div>;
};

export default NewRedirectPage;
