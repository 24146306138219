import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';

function isWithinOneHour(base64Timestamp) {
  // Base64デコード
  const decodedTimestamp = atob(base64Timestamp);

  // Unixタイムスタンプに変換
  const timestamp = parseInt(decodedTimestamp, 10);

  // 現在のUnixタイムスタンプをUTCで取得
  const currentTimestamp = Math.floor(new Date().getTime() / 1000);

  // 1時間以内かどうかを判定
  const oneHourInSeconds = 60 * 60;
  return Math.abs(currentTimestamp - timestamp) <= oneHourInSeconds;
}

const WatchSwapPage = (props) => {
  const { swapId, timestamp } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (isWithinOneHour(timestamp)) {
      if (swapId == 'cancel') {
        window.location.href = `https://pornswap.net/create`;
      } else {
        window.location.href = `https://pornswap.net/signup/${swapId}`;
      }
    } else {
      navigate('/');
    }
  }, []);

  return <div></div>;
};

export default WatchSwapPage;
