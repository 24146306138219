import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';


const RedirectPage = (props) => {
  const [searchParams] = useSearchParams();
  const url = searchParams.get("url");

  useEffect(() => {
    window.location.href = url;
  }, []);

  return <div></div>;
};

export default RedirectPage;
