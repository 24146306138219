import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Center = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
`;

const Container = styled.div`
  flex-direction: column;
  color: white;
  width: 500px;
  margin: 25px 20px 20px 20px;
  display: flex;
  font-weight: 500;
  color: #D1D1D1;
`;

const TopContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const Video = styled.video`
  width: 100vw;
`;

const FacesVideo = styled.video`
  width: 100%;
  border-radius: 10px;
  border: 1px solid #424242;
  margin-top: 15px;
`;

const TopText = styled.div`
  position: absolute;
  z-index: 2;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Logo = styled.div`
  font-weight: bold;
  color: #7fed64;
  text-shadow: 0 0 15px rgba(0, 0, 0, 1);
  text-align: center;
`;

const TopLogo = styled.div`
  font-size: 45px;

  @media (max-width: 560px) {
    font-size: 30px;
  }
`;

const MiniLogo = styled.div`
  font-size: 35px;

  @media (max-width: 560px) {
    font-size: 20px;
  }
`;

const ConceptText = styled.div`
  color: white;
  text-shadow: 0 0 15px black;
  font-size: 20px;
  font-weight: bold;
  margin-top: 10px;
  text-align: center;

  @media (max-width: 560px) {
    font-size: 15px;
  }
`;

const CreateButton = styled.span`
  margin-top: 10px;
  border-radius: 5px;
  height: 44px;

  background-color: rgb(254, 167, 0);
  color: black;
  padding: 10px;

  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: bold;
  color: black;

  &:hover {
    background-color: #f8be53;
  }
`;

const PornSiteIconList = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
`;

const PornSiteIconListCenter = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
`;

const PornSiteIcon = styled.img`
  height: ${x=> x.height};
`;

const StepImg = styled.img`
  width: calc(100% - 20px);
  margin: 10px;
`;

const StepImgContainer = styled.div`
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #1d1d1d;
  border-radius: 10px;
  border: 1px solid gray;
`;

const StepB = styled.span`
  font-weight: bold;
  color: #7fed64;
`;

const StepSubtitle = styled.span`
  font-weight: bold;
  font-size: 15px;
  color: #C0C0C0;
`;

const StepTitle = styled.legend`
  font-weight: 700;
  font-size: 18px;
`;

const StepContainer = styled.fieldset`
  border-radius: 10px;
  background-color: #2b2b2b;
  border: 1px solid #424242;
  padding: 20px;
  margin-top: 20px;
`;

const SectionTitle = styled.div`
  margin-top: 20px;
  font-size: 30px;
  font-weight: bold;
  width: 100%;
  text-align: center;
  color: #7fed64;

  @media (max-width: 560px) {
    font-size: 20px;
  }
`;

const SectionText = styled.div`
  font-size: 18px;
  width: 100%;
  margin-top: 10px;

  @media (max-width: 560px) {
    font-size: 16px;
  }
`;

const TweetImgContainer = styled.div` 
  margin-top: 20px;
  border-radius: 15px;
  display: flex;
  padding: 5px;
  padding-top: 15px;
  background-color: white;
  position: relative;
`;

const TweetCatch = styled.span`
  position: absolute;
  font-size: 20px;
  z-index: 100;
  color: black;
  right: 20px;
  font-weight: bold;
`;

const TweetImg = styled.img`
  width: 100%;
`;

const TweetVideo = styled.video`
  position: absolute;
  z-index: 50;
  width: 100%;
  border-radius: 10px;
  width: ${x => x.width};
  top: ${x => x.top};
  left: ${x => x.left};
`;

const ExTitle = styled.div`
  color: #7fed64;
  font-size: 25px;
  font-weight: bold;
  width: 100%;
  text-align: center;
  margin-top: 15px;

  @media (max-width: 560px) {
    font-size: 20px;
  }
`;

const ExText = styled.div`
  font-size: 16px;
  width: 100%;
  margin-top: 10px;

  @media (max-width: 560px) {
    font-size: 14px;
  }
`;

const ExVideo = styled.video`
  width: 100%;
  border-radius: 10px;
  border: 1px solid #424242;
  margin-bottom: 15px;
  margin-top: 10px;
`;

const PriceImg = styled.img`
  width: 100%;
  border-radius: 15px;
  border: 1px solid #424242;
  margin-top: 10px;
`;

const LinkButtonContainer = styled.div`
  margin-top: 20px;
`;

const LogoContainer = styled.div`
  background-color: rgba(0, 0, 0, 0.3);
  padding: 15px;
  border-radius: 15px;
  border: 1px solid #7fed64;

  @media (max-width: 560px) {
    padding: 10px;
  }
`;

const PriceText = styled.div`
  width: 100%;
  text-align: center;
  font-size: 30px;
  margin-bottom: 10px;
`;

const Emphasis = styled.span`
  font-weight: bold;
  color: white;
`;

const AwardContainer = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 10px;
  display: flex;
`;

const AwardImg = styled.img`
  max-width: 150px;
`;

const AwardText = styled.div`
  text-align: left;
  display: flex;
  align-items: center;
`;

const LinkButton = (props) => {
  return <LinkButtonContainer>
    <Link to="/create">
      <CreateButton>
        Let's face swap porn in 10 seconds!
      </CreateButton>
    </Link>
  </LinkButtonContainer>;
};

const HomePage = (props) => {
  return <><Center>
    <Container>
      <TopContainer>
        <Video src="https://d3phaj0sisr2ct.cloudfront.net/research/vallee-generation-3.webm" autoPlay loop muted playsInline/>
        <TopText>
          <LogoContainer>
            <Logo>
              <TopLogo>
                Video AI Generation
              </TopLogo>
              <MiniLogo>
                in 10 seconds
              </MiniLogo>
            </Logo>
            <ConceptText>
              Let's realize your imagination!
            </ConceptText>
          </LogoContainer>
        </TopText>
      </TopContainer>

      <SectionTitle>
        Revolutionize your creativity
      </SectionTitle>
      <SectionText>
      We have the best AI that can generate a video in just 10 seconds. A variety of features will bring innovation to your creative process.
      </SectionText>

      <SectionTitle>
        Application of state-of-the-art <br/> AI technology
      </SectionTitle>
      <SectionText>
        We use the most accurate AI model in the world, developed by researchers at Google and Meta, which has won several international AI competitions.
        Experience the world's best technology!
      </SectionText>

      <AwardContainer>
        <AwardText>
          2023.12: Rank 1st of ECTV 2023 WCAP Challenge. <br />
          2023.10: Rank 1st of NEST-FRXT 1:1. <br />
          2023.09: Rank 1st of WILDER Video Generation Challenge 2023.
        </AwardText>
        <AwardImg src="/imgs/awards.png" />
      </AwardContainer>

      <SectionTitle>
        Make your dreams come true ✨
      </SectionTitle>

      <ExTitle>
        Stylization
      </ExTitle>
      <ExText>
        Transfer the style of any image or prompt to every frame of your video.
      </ExText>
      <ExVideo src="https://d3phaj0sisr2ct.cloudfront.net/research/gen1/videos/dancer/dancer_generated.mp4" autoPlay loop muted playsInline/>

      <ExTitle>
        Render
      </ExTitle>
      <ExText>
        Turn mockups into fully stylized and animated renders.
      </ExText>
      <ExVideo src="https://d3phaj0sisr2ct.cloudfront.net/research/gen1/videos/storyboard/storyboard_generated-gen.mp4" autoPlay loop muted playsInline/>

      <ExTitle>
        Render
      </ExTitle>
      <ExText>
        Turn untextured renders into realistic outputs by applying an input image or prompt.
      </ExText>
      <ExVideo src="https://d3phaj0sisr2ct.cloudfront.net/research/gen1/videos/storyboard/gen-1-render.mp4" autoPlay loop muted playsInline/>

      <SectionTitle>
        Price 💰
      </SectionTitle>
      <SectionText>
        <PriceText>
          $9.99 / a video
        </PriceText>
        Simple pricing at just $9.99 per video generated. There are no subscriptions. Before payment, a still image provides a preview.
      </SectionText>
    </Container>
  </Center>
  </>
};

export default HomePage;
