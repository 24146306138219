import './App.css';
import { Link, NavLink, Route, Routes, useSearchParams } from 'react-router-dom';
import HomePage from './HomePage';
import styled, { keyframes } from 'styled-components';
import WatchSwapPage from './WatchSwapPage';
import RedirectPage from './RedirectPage';
import NewRedirectPage from './NewRedirectPage';

const MainContainer = styled.div`
  color: #dcdcdc;
  height: 100vh;
  width: 100vw;
  font-family: ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
`;

const Gaming = keyframes`
  100% { background-position-x: 200%; }
`;

const Title = styled.div`
  color: #fa6d7d;
  font-size: 70px;
  font-weight: bold;
  text-align: center;
  animation: ${Gaming} 2s linear infinite;
`;

const Header = styled.header`
  color: "black";
  display: flex;
  flex-direction: row;
`;

const HeaderItem = styled.header`
  color: rgb(255, 255, 238);
  font-size: 17px;
  font-weight: 500;
  cursor: pointer;
  border-radius: 5px;
  padding: 5px 20px;

  @media (max-width: 560px) {
    padding: 5px 0px;
  }
`;

const HeaderItem2 = styled.header`
  color: rgb(255, 255, 238);
  font-size: 20px;
  font-weight: 700;
  cursor: pointer;
  border-radius: 5px;
  padding: 20px;
`;

const StyledLogoLink = styled(NavLink)`
  border-bottom: 2px solid #424242;
`;

const StyledNavLink = styled(NavLink)`
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 2px solid #424242;

  &.active {
      border-bottom: 2px solid rgb(254, 167, 0);
  }

  &:hover {
    background-color: #272727;
  }

  @media (max-width: 560px) {
    flex: 1;
    &:hover {
      background-color: transparent;
    }
  }
`;

const Footer = styled.footer`
  background-color: #292929;
  padding: 40px;
  padding-bottom: 60px;
`;

const FooterHeader = styled.h1`
  font-size: 18px;
`;

const FooterItem = styled.div`
  color: #a0a0a0;
  font-weight: 500;
`;

const UserIcon = styled.img`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #424242;
  padding: 5px;
  margin: 0px 20px;
`;

const Space = styled.div`
  flex: 1;
  border-bottom: 2px solid #424242;
`;

const Credit = styled.span`
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  padding-right: 10px;
  border-bottom: 2px solid #424242;
`;

const CreditIcon = styled.img`
  width: 25px;
  margin-right: 7px;
  -webkit-filter: invert(100%);
  filter: invert(100%);
`;

const Bottom = styled.div`
  height: 75px;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  padding-right: 20px;
`;

function App() {
  return <MainContainer>
    <Header>
      <StyledLogoLink to="/"><HeaderItem2>VideoAI</HeaderItem2></StyledLogoLink>
    </Header>
    <Routes>
      <Route index element={<HomePage />} />
      <Route path="/swaps/:swapId/:timestamp" element={<WatchSwapPage />} />
      <Route path="/redirect" element={<RedirectPage />} />
      <Route path="/new/:timestampToken/:urlToken" element={<NewRedirectPage />} />
    </Routes>
    <Footer>
      <FooterHeader>Information</FooterHeader>
      <Link to="/"><FooterItem>About</FooterItem></Link>
    </Footer>
    <Bottom>
      © 2024 VideoAI. All Rights Reserved.
    </Bottom>
  </MainContainer>;
}

export default App;
